import router from '@/router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false }) // NProgress Configuration
router.beforeEach((to, from, next) => {
  if (to.query.hotelname && to.query.hotelname.toLocaleUpperCase() == "MSNWE" && !to.query.location) {
    window.location.href =
      "https://qrcode.hotelservicebot.com/pdfView?inno=https%3A%2F%2Fhotelfile353485474178.s3-accelerate.amazonaws.com%2Fqrfiles%2FMSNWE%2FDinner_Menu_1.24.24_0314.pdf&showClose=false";

  } else {
    NProgress.start()
    // set page title
    document.title = to.meta.title || process.env.VUE_APP_TITLE
    next()
  }
})
router.afterEach(() => {
  NProgress.done() // 结束进度条
})
