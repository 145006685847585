import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home"),
    alias: "/home",
    meta: { title: "IN ROOM DINING" },
  },
  {
    path: "/confirmOrder",
    name: "ConfirmOrder",
    component: () => import("@/views/ConfirmOrder"),
    meta: { title: "IN ROOM DINING" },
    hidden: true,
  },
  {
    path: "/placeOrder",
    name: "PlaceOrder",
    component: () => import("@/views/PlaceOrder"),
    meta: { title: "IN ROOM DINING" },
    hidden: true,
  },
  {
    path: "/myOrder",
    name: "Order",
    component: () => import("@/views/Order"),
    meta: { title: "IN ROOM DINING" },
    hidden: true,
  },
  {
    path: "/orderDetail",
    name: "OrderDetail",
    component: () => import("@/views/OrderDetail"),
    meta: { title: "IN ROOM DINING" },
  },
  {
    path: "/orderDetailNew",
    name: "OrderDetailNew",
    component: () => import("@/views/OrderDetailNew"),
    meta: { title: "IN ROOM DINING" },
  },
  {
    path: "/parkingList",
    name: "ParkingList",
    component: () => import("@/views/ParkingList"),
    meta: { title: "IN ROOM DINING" },
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: { title: "About" },
  },
  {
    path: "/404",
    name: "Page404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
