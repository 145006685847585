import Vue from 'vue';
import axios from "axios";
import { Toast } from "vant";
import _ from 'lodash';


let loadingInstance;
let needLoadingRequestCount = 0;

function showLoading() {
  let main = document.querySelector('#app')
  if (main) {
    if (needLoadingRequestCount === 0 && !loadingInstance) {
      loadingInstance = Toast.loading({
        forbidClick: true,
        duration: 0,
      });
    }
    needLoadingRequestCount++;
  }
}
function closeLoading() {
  Vue.nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
    needLoadingRequestCount--;
    needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); // 保证大于等于0
    if (needLoadingRequestCount === 0) {
      if (loadingInstance) {
        hideLoading()
      }
    }
  });
}

var hideLoading = _.debounce(() => {
  loadingInstance.clear();
  loadingInstance = null;
}, 800);


// 创建axios实例
const instance = axios.create({
  baseURL: process.env.VUE_APP_PROXY_PREFIX,
  // baseURL: 'https://hvt.hibaysoft.com/AdminLayout/MealPeriod',
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // 跨域请求时发送 cookies
  timeout: 50000, // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
});
// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    if (localStorage.getItem("HTTOKEN"))
      config.headers.HTTOKEN = `${localStorage.getItem("HTTOKEN")}`;
    showLoading()
    return config;
  },
  (error) => {
    closeLoading()
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    closeLoading()
    return response.data;
  },
  (error) => {
    closeLoading()
    return Promise.reject(error);
  }
);

export default instance;
