const getters = {
  foods: (state) => state.food.foods,
  order: (state) => state.food.order,
  allPrice: (state) => state.food.allPrice,
  allNum: (state) => state.food.allNum,
  mealPeriodOpenTime: (state) => state.food.mealPeriodOpenTime,
  discount: (state) => state.food.discount,
  primaryColor: (state) => state.food.primaryColor,
};
export default getters;
