import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission";
import "amfe-flexible";
import "./icons";
import Vant from "vant";
import '@vant/touch-emulator';
import "vant/lib/index.css";
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import "./utils/vConsole";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import '@/assets/scss/common.scss';
import { Base64 } from "js-base64";

Vue.use(Antd);
Vue.use(Vant);
Vue.use(Base64);

Locale.use('en-US', enUS);

Vue.config.productionTip = false;
Vue.prototype.$Base64 = Base64;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
