const food = {
  state: {
    foods: [],
    order: [],
    allPrice: 0,
    allNum: 0,
    mealPeriodOpenTime: [],
    discount: null,
    primaryColor: "",
  },
  mutations: {
    ADD_FOOD: (state, data) => {
      state.foods.push(data);
      state.allPrice = 0;
      state.allNum = 0;
      for (let i = 0; i < state.foods.length; i++) {
        const element = state.foods[i];
        state.allPrice += element.allPrice;
        state.allNum += element.num;
      }
    },
    REMOVE_FOOD: (state, data) => {
      state.foods = state.foods.filter((item) => {
        return item.id != data;
      });
      state.allPrice = 0;
      state.allNum = 0;
      for (let i = 0; i < state.foods.length; i++) {
        const element = state.foods[i];
        state.allPrice += element.allPrice;
        state.allNum += element.num;
      }
    },
    EDIT_FOOD: (state, data) => {
      for (let i = 0; i < state.foods.length; i++) {
        if (state.foods[i].id == data.id) {
          state.foods[i] = data;
        }
      }
      state.allPrice = 0;
      state.allNum = 0;
      for (let i = 0; i < state.foods.length; i++) {
        const element = state.foods[i];
        state.allPrice += element.allPrice;
        state.allNum += element.num;
      }
    },
    CLEAR_FOOD: (state) => {
      state.foods = [];
      state.allNum = 0
      state.allPrice = 0;
    },
    ADD_ORDER: (state, data) => {
      state.order = data;
      state.foods = [];
      state.allPrice = 0;
      state.allNum = 0;
    },
    CLEAR_ORDER: (state) => {
      state.order = [];
    },
    UPDETE_MEAL_PERIOD_OPEN_TIME: (state, data) => {
      let target = state.mealPeriodOpenTime.find((item) => {
        return item.mealPeriodId == data.mealPeriodId;
      });
      if (!target) {
        state.mealPeriodOpenTime.push(data);
      } else {
        for (let i = 0; i < state.mealPeriodOpenTime.length; i++) {
          if (state.mealPeriodOpenTime[i].mealPeriodId == data.mealPeriodId) {
            state.mealPeriodOpenTime[i] = data;
            break;
          }
        }
      }
    },
    EDIT_COLOR: (state, data) => {
      state.primaryColor = data;
    },
    ADD_DISCOUNT: (state, data) => {
      state.discount = data;
    },
    REMOVE_DISCOUNT: (state) => {
      state.discount = null;
    },
    RESET_STATE: (state) => {
      state.foods = [];
      state.order = [];
      state.allPrice = 0;
      state.allNum = 0;
      state.mealPeriodOpenTime = [];
      state.discount = null;
      state.primaryColor = "";
    },
  },
  actions: {
    addFood: ({ commit }, data) => {
      commit("ADD_FOOD", data);
    },
    removeFood: ({ commit }, data) => {
      commit("REMOVE_FOOD", data);
    },
    clearFood: ({ commit }) => {
      commit("CLEAR_FOOD");
    },
    editFood: ({ commit }, data) => {
      commit("EDIT_FOOD", data);
    },
    addOrder: ({ commit }, data) => {
      commit("ADD_ORDER", data);
    },
    clearOrder: ({ commit }) => {
      commit("CLEAR_ORDER");
    },
    updateMealPeriodOpenTime: ({ commit }, data) => {
      commit("UPDETE_MEAL_PERIOD_OPEN_TIME", data);
    },
    editColor: ({ commit }, data) => {
      commit("EDIT_COLOR", data);
    },
    addDiscount: ({ commit }, data) => {
      commit("ADD_DISCOUNT", data);
    },
    removeDiscount: ({ commit }) => {
      commit("REMOVE_DISCOUNT");
    },
    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};

export default food;
