import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import getters from "./getters";
import user from "./modules/user";
import food from "./modules/food";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    food,
  },
  getters,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
